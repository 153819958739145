<template>
  <div class="dabox">
    <div class="se shu shu1"></div>
    <div class="se shu shu2"></div>
    <div class="se shu shu3"></div>
    <div class="se heng"></div>
    <div class="dingwei1">
      <p class="wenzi wenzi1">CW</p>
      <p class="wenzi wenzi2">CW</p>
      <p class="wenzi wenzi3">CW</p>
      <p class="wenzi wenzi4">CW</p>
      <p class="wenzi wenzi5">CW</p>
      <p class="wenzi wenzi6">6# 釜趋势</p>
      <p class="wenzi wenzi7">聚合釜F</p>
      <div class="funei">
        <img
          :src="
            infoList.DEVICE1S.II01 || infoList.DEVICE1S.II02 || 0 | tyFifter
          "
          alt=""
        />
      </div>
      <!-- <div class="famen">
        <img :src="11 | tyFifter" alt="" />
      </div> -->
      <p
        class="shuzhi shuzhi1"
        @click="
          toCompon(2, 'AV03_FYF', 'DEVICE1S', 'AV03', '稳定性得分回写DCS')
        "
        @dblclick="
          Cclick(infoList.DEVICE1S.AV03, 'AV03_FYF', 'DEVICE1S', 'AV03')
        "
      >
        {{ infoList.DEVICE1S.AV03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi2"
        @click="toCompon(2, 'VP09', 'DEVICE1S', 'VP09_FYF', '备用阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE1S.VP09, 'VP09', 'DEVICE1S', 'VP09_FYF')
        "
      >
        {{ infoList.DEVICE1S.VP09 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi3"
        @click="toCompon(2, 'TE01', 'DEVICE1S', 'TE01_FYF', '夹套水出口温度')"
        @dblclick="
          Cclick(infoList.DEVICE1S.TE01, 'TE01', 'DEVICE1S', 'TE01_FYF')
        "
      >
        {{ infoList.DEVICE1S.TE01 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi4"
        @click="
          toCompon(2, 'FT01', 'DEVICE1S', 'FT01_FYF', '夹套冷却水出口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE1S.FT01, 'FT01', 'DEVICE1S', 'FT01_FYF')
        "
      >
        {{ infoList.DEVICE1S.FT01 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi5"
        @click="
          toCompon(2, 'VP02', 'DEVICE1S', 'VP02_FYF', '旁路冷却水阀DCS输出')
        "
        @dblclick="
          Cclick(infoList.DEVICE1S.VP02, 'VP02', 'DEVICE1S', 'VP02_FYF')
        "
      >
        {{ infoList.DEVICE1S.VP02 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi6"
        @click="toCompon(2, 'VP01', 'DEVICE1S', 'VP01_FYF', '冷却水阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE1S.VP01, 'VP01', 'DEVICE1S', 'VP01_FYF')
        "
      >
        {{ infoList.DEVICE1S.VP01 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi7"
        @click="toCompon(2, 'TE02', 'DEVICE1S', 'TE02_FYF', '冷却水进口温度')"
        @dblclick="
          Cclick(infoList.DEVICE1S.TE02, 'TE02', 'DEVICE1S', 'TE02_FYF')
        "
      >
        {{ infoList.DEVICE1S.TE02 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi8"
        @click="
          toCompon(
            2,
            'VP03',
            'DEVICE1S',
            'VP03_FYF',
            'F釜夹套冷却水进口调节阀程控设定值'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE1S.VP03, 'VP03', 'DEVICE1S', 'VP03_FYF')
        "
      >
        {{ infoList.DEVICE1S.VP03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi9"
        @click="toCompon(2, 'II01', 'DEVICE1S', 'GL01_FYF', '高搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE1S.II01, 'II01', 'DEVICE1S', 'GL01_FYF')
        "
      >
        {{ infoList.DEVICE1S.II01 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi10"
        @click="toCompon(2, 'II02', 'DEVICE5S', 'GL02_FYF', '低搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE5S.II02, 'II02', 'DEVICE5S', 'GL02_FYF')
        "
      >
        {{ infoList.DEVICE5S.II02 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi11"
        @click="
          toCompon(2, 'TE07', 'DEVICE1S', 'TE07_FYF', '釜顶冷冷却水出口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE1S.TE07, 'TE07', 'DEVICE1S', 'TE07_FYF')
        "
      >
        {{ infoList.DEVICE1S.TE07 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi12"
        @click="
          toCompon(2, 'TE06', 'DEVICE1S', 'TE06_FYF', '釜顶冷冷却水进口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE1S.TE06, 'TE06', 'DEVICE1S', 'TE06_FYF')
        "
      >
        {{ infoList.DEVICE1S.TE06 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi13"
        @click="
          toCompon(
            2,
            'VP08',
            'DEVICE1S',
            'VP08_FYF',
            '釜顶冷冷却水出口阀DCS输出'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE1S.VP08, 'VP08', 'DEVICE1S', 'VP08_FYF')
        "
      >
        {{ infoList.DEVICE1S.VP08 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi14"
        @click="
          toCompon(2, 'FT07', 'DEVICE1S', 'FT07_FYF', '釜顶冷冷却水进口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE1S.FT07, 'FT07', 'DEVICE1S', 'FT07_FYF')
        "
      >
        {{ infoList.DEVICE1S.FT07 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi15"
        @click="
          toCompon(2, 'FT08', 'DEVICE1S', 'FT08_FYF', '釜顶冷冷却水换热值')
        "
        @dblclick="
          Cclick(infoList.DEVICE1S.FT08, 'FT08', 'DEVICE1S', 'FT08_FYF')
        "
      >
        {{ infoList.DEVICE1S.FT08 || "0.00" }} Tcal/h
      </p>
      <p
        class="shuzhi lan shuzhi16"
        @click="toCompon(2, 'TE03', 'DEVICE1S', 'TE03_FYF', '反应釜温度2')"
        @dblclick="
          Cclick(infoList.DEVICE1S.TE03, 'TE03', 'DEVICE1S', 'TE03_FYF')
        "
      >
        {{ infoList.DEVICE1S.TE03 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi17"
        @click="toCompon(2, 'PT01', 'DEVICE1S', 'PT01_FYF', '反应釜压力')"
        @dblclick="
          Cclick(infoList.DEVICE1S.PT01, 'PT01', 'DEVICE1S', 'PT01_FYF')
        "
      >
        {{ infoList.DEVICE1S.PT01 || "0.00" }} MPa
      </p>
      <p
        class="shuzhi lan shuzhi18"
        @click="toCompon(2, 'TE04', 'DEVICE1S', 'TE04_FYF', '反应釜温度1')"
        @dblclick="
          Cclick(infoList.DEVICE1S.TE04, 'TE04', 'DEVICE1S', 'TE04_FYF')
        "
      >
        {{ infoList.DEVICE1S.TE04 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi19"
        @click="toCompon(2, 'AI02', 'DEVICE1S', 'AI02_FYF', '聚合釜反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE1S.AI02, 'AI02', 'DEVICE1S', 'AI02_FYF')
        "
      >
        {{ infoList.DEVICE1S.AI02 || "0.00" }} 分
      </p>
      <p
        class="shuzhi lan shuzhi20"
        @click="toCompon(2, 'AN10', 'DEVICE1V', 'AN10_FYF', '反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE1V.AN10, 'AN10', 'DEVICE1V', 'AN10_FYF')
        "
      >
        {{ infoList.DEVICE1V.AN10 || "0.00" }} 分
      </p>
      <p
        class="shuzhi shuzhi21"
        @click="toCompon(2, 'AN25', 'DEVICE1V', 'AN25_FYF', '釜温60S变化速率')"
        @dblclick="
          Cclick(infoList.DEVICE1V.AN25, 'AN25', 'DEVICE1V', 'AN25_FYF')
        "
      >
        {{ infoList.DEVICE1V.AN25 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi22"
        @click="
          toCompon(2, 'AN21', 'DEVICE1V', 'AN21_FYF', '30S釜压变化速率*1000')
        "
        @dblclick="
          Cclick(infoList.DEVICE1V.AN21, 'AN21', 'DEVICE1V', 'AN21_FYF')
        "
      >
        {{ infoList.DEVICE1V.AN21 || "0.00" }}
      </p>
      <p
        class="shuzhi lan shuzhi23"
        @click="
          toCompon(
            2,
            'AN22',
            'DEVICE1V',
            'AN22_FYF',
            '釜压高限设定（全开循环水）'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE1V.AN22, 'AN22', 'DEVICE1V', 'AN22_FYF')
        "
      >
        {{ infoList.DEVICE1V.AN22 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi24"
        @click="toCompon(2, 'DG03', 'DEVICE1V', 'DG03_FYF', '釜压高标志')"
        @dblclick="
          Cclick(infoList.DEVICE1V.DG03, 'DG03', 'DEVICE1V', 'DG03_FYF')
        "
      >
        {{ infoList.DEVICE1V.DG03 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi25"
        @click="
          toCompon(2, 'AN20', 'DEVICE1V', 'AN20_FYF', '引发剂A/B配方量总和')
        "
        @dblclick="
          Cclick(infoList.DEVICE1V.AN20, 'AN20', 'DEVICE1V', 'AN20_FYF')
        "
      >
        {{ infoList.DEVICE1V.AN20 || "0.00" }} kg
      </p>
      <p class="caozuo caozuo1"  :style="{
              background:
                infoList.DEVICE1M__p__MAN07 &&
                infoList.DEVICE1M__p__MAN07.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE1M__p__MAN07
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE1M__p__MAN07',
                    'MAN07_RM_FYF',
                    '备用手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo2" :style="{
              background:
                infoList.DEVICE1M__p__MAN02 &&
                infoList.DEVICE1M__p__MAN02.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE1M__p__MAN02
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE1M__p__MAN02',
                    'MAN02_RM_FYF',
                    '内冷水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo3" :style="{
              background:
                infoList.DEVICE1M__p__MAN01 &&
                infoList.DEVICE1M__p__MAN01.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE1M__p__MAN01
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE1M__p__MAN01',
                    'MAN01_RM_FYF',
                    '夹套水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <!-- <p class="liandong liandong1">F 联动</p> -->
      <!-- <p class="liandong liandong2">联锁</p> -->
    </div>
    <div class="dingwei2">
      <p class="wenzi wenzi1">CW</p>
      <p class="wenzi wenzi2">CW</p>
      <p class="wenzi wenzi3">CW</p>
      <p class="wenzi wenzi4">CW</p>
      <p class="wenzi wenzi5">CW</p>
      <p class="wenzi wenzi6">7# 釜趋势</p>
      <p class="wenzi wenzi7">聚合釜G</p>
      <div class="funei">
        <img
          :src="
            infoList.DEVICE2S.II01 || infoList.DEVICE2S.II02 || 0 | typeFifter
          "
          alt=""
        />
      </div>
      <!-- <div class="famen">
        <img :src="11 | tyFifter" alt="" />
      </div> -->
      <p
        class="shuzhi shuzhi1"
        @click="
          toCompon(2, 'AV03', 'DEVICE2S', 'AV03_FYF', '稳定性得分回写DCS')
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.AV03, 'AV03', 'DEVICE2S', 'AV03_FYF')
        "
      >
        {{ infoList.DEVICE2S.AV03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi1"
        @click="
          toCompon(2, 'AV03', 'DEVICE2S', 'AV03_FYF', '稳定性得分回写DCS')
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.AV03, 'AV03', 'DEVICE2S', 'AV03_FYF')
        "
      >
        {{ infoList.DEVICE2S.AV03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi2"
        @click="toCompon(2, 'VP09', 'DEVICE2S', 'VP09_FYF', '备用阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE2S.VP09, 'VP09', 'DEVICE2S', 'VP09_FYF')
        "
      >
        {{ infoList.DEVICE2S.VP09 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi3"
        @click="toCompon(2, 'TE01', 'DEVICE2S', 'TE01_FYF', '夹套水出口温度')"
        @dblclick="
          Cclick(infoList.DEVICE2S.TE01, 'TE01', 'DEVICE2S', 'TE01_FYF')
        "
      >
        {{ infoList.DEVICE2S.TE01 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi4"
        @click="
          toCompon(2, 'FT01', 'DEVICE2S', 'FT01_FYF', '夹套冷却水出口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.FT01, 'FT01', 'DEVICE2S', 'FT01_FYF')
        "
      >
        {{ infoList.DEVICE2S.FT01 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi5"
        @click="
          toCompon(2, 'VP02', 'DEVICE2S', 'VP02_FYF', '旁路冷却水阀DCS输出')
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.VP02, 'VP02', 'DEVICE2S', 'VP02_FYF')
        "
      >
        {{ infoList.DEVICE2S.VP02 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi6"
        @click="toCompon(2, 'VP01', 'DEVICE2S', 'VP01_FYF', '冷却水阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE2S.VP01, 'VP01', 'DEVICE2S', 'VP01_FYF')
        "
      >
        {{ infoList.DEVICE2S.VP01 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi7"
        @click="toCompon(2, 'TE02', 'DEVICE2S', 'TE02_FYF', '冷却水进口温度')"
        @dblclick="
          Cclick(infoList.DEVICE2S.TE02, 'TE02', 'DEVICE2S', 'TE02_FYF')
        "
      >
        {{ infoList.DEVICE2S.TE02 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi8"
        @click="
          toCompon(
            2,
            'VP03',
            'DEVICE2S',
            'VP03_FYF',
            'F釜夹套冷却水进口调节阀程控设定值'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.VP03, 'VP03', 'DEVICE2S', 'VP03_FYF')
        "
      >
        {{ infoList.DEVICE2S.VP03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi9"
        @click="toCompon(2, 'II01', 'DEVICE2S', 'GL01_FYF', '高搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE2S.II01, 'II01', 'DEVICE2S', 'GL01_FYF')
        "
      >
        {{ infoList.DEVICE2S.II01 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi10"
        @click="toCompon(2, 'II02', 'DEVICE2S', 'GL02_FYF', '低搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE2S.II02, 'II02', 'DEVICE2S', 'GL02_FYF')
        "
      >
        {{ infoList.DEVICE2S.II02 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi11"
        @click="
          toCompon(2, 'TE07', 'DEVICE2S', 'TE07_FYF', '釜顶冷冷却水出口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.TE07, 'TE07', 'DEVICE2S', 'TE07_FYF')
        "
      >
        {{ infoList.DEVICE2S.TE07 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi12"
        @click="
          toCompon(2, 'TE06', 'DEVICE2S', 'TE06_FYF', '釜顶冷冷却水进口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.TE06, 'TE06', 'DEVICE2S', 'TE06_FYF')
        "
      >
        {{ infoList.DEVICE2S.TE06 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi13"
        @click="
          toCompon(
            2,
            'VP08',
            'DEVICE2S',
            'VP08_FYF',
            '釜顶冷冷却水出口阀DCS输出'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.VP08, 'VP08', 'DEVICE2S', 'VP08_FYF')
        "
      >
        {{ infoList.DEVICE2S.VP08 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi14"
        @click="
          toCompon(2, 'FT07', 'DEVICE2S', 'FT07_FYF', '釜顶冷冷却水进口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.FT07, 'FT07', 'DEVICE2S', 'FT07_FYF')
        "
      >
        {{ infoList.DEVICE2S.FT07 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi15"
        @click="
          toCompon(2, 'FT08', 'DEVICE2S', 'FT08_FYF', '釜顶冷冷却水换热值')
        "
        @dblclick="
          Cclick(infoList.DEVICE2S.FT08, 'FT08', 'DEVICE2S', 'FT08_FYF')
        "
      >
        {{ infoList.DEVICE2S.FT08 || "0.00" }} Tcal/h
      </p>
      <p
        class="shuzhi lan shuzhi16"
        @click="toCompon(2, 'TE03', 'DEVICE2S', 'TE03_FYF', '反应釜温度2')"
        @dblclick="
          Cclick(infoList.DEVICE2S.TE03, 'TE03', 'DEVICE2S', 'TE03_FYF')
        "
      >
        {{ infoList.DEVICE2S.TE03 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi17"
        @click="toCompon(2, 'PT01', 'DEVICE2S', 'PT01_FYF', '反应釜压力')"
        @dblclick="
          Cclick(infoList.DEVICE2S.PT01, 'PT01', 'DEVICE2S', 'PT01_FYF')
        "
      >
        {{ infoList.DEVICE2S.PT01 || "0.00" }} MPa
      </p>
      <p
        class="shuzhi lan shuzhi18"
        @click="toCompon(2, 'TE04', 'DEVICE2S', 'TE04_FYF', '反应釜温度1')"
        @dblclick="
          Cclick(infoList.DEVICE2S.TE04, 'TE04', 'DEVICE2S', 'TE04_FYF')
        "
      >
        {{ infoList.DEVICE2S.TE04 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi19"
        @click="toCompon(2, 'AI02', 'DEVICE2S', 'AI02_FYF', '聚合釜反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE2S.AI02, 'AI02', 'DEVICE2S', 'AI02_FYF')
        "
      >
        {{ infoList.DEVICE2S.AI02 || "0.00" }} 分
      </p>
      <p
        class="shuzhi lan shuzhi20"
        @click="toCompon(2, 'AN10', 'DEVICE2V', 'AN10_FYF', '反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE2V.AN10, 'AN10', 'DEVICE2V', 'AN10_FYF')
        "
      >
        {{ infoList.DEVICE2V.AN10 || "0.00" }} 分
      </p>
      <p
        class="shuzhi shuzhi21"
        @click="toCompon(2, 'AN25', 'DEVICE2V', 'AN25_FYF', '釜温60S变化速率')"
        @dblclick="
          Cclick(infoList.DEVICE2V.AN25, 'AN25', 'DEVICE2V', 'AN25_FYF')
        "
      >
        {{ infoList.DEVICE2V.AN25 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi22"
        @click="
          toCompon(2, 'AN21', 'DEVICE2V', 'AN21_FYF', '30S釜压变化速率*1000')
        "
        @dblclick="
          Cclick(infoList.DEVICE2V.AN21, 'AN21', 'DEVICE2V', 'AN21_FYF')
        "
      >
        {{ infoList.DEVICE2V.AN21 || "0.00" }}
      </p>
      <p
        class="shuzhi lan shuzhi23"
        @click="
          toCompon(
            2,
            'AN22',
            'DEVICE2V',
            'AN22_FYF',
            '釜压高限设定（全开循环水）'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE2V.AN22, 'AN22', 'DEVICE2V', 'AN22_FYF')
        "
      >
        {{ infoList.DEVICE2V.AN22 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi24"
        @click="toCompon(2, 'DG03', 'DEVICE2V', 'DG03_FYF', '釜压高标志')"
        @dblclick="
          Cclick(infoList.DEVICE2V.DG03, 'DG03', 'DEVICE2V', 'DG03_FYF')
        "
      >
        {{ infoList.DEVICE2V.DG03 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi25"
        @click="
          toCompon(2, 'AN20', 'DEVICE2V', 'AN20_FYF', '引发剂A/B配方量总和')
        "
        @dblclick="
          Cclick(infoList.DEVICE2V.AN20, 'AN20', 'DEVICE2V', 'AN20_FYF')
        "
      >
        {{ infoList.DEVICE2V.AN20 || "0.00" }} kg
      </p>
      <p class="caozuo caozuo1"  :style="{
              background:
                infoList.DEVICE2M__p__MAN07 &&
                infoList.DEVICE2M__p__MAN07.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE2M__p__MAN07
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE2M__p__MAN07',
                    'MAN07_RM_FYF',
                    '备用手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo2" :style="{
              background:
                infoList.DEVICE2M__p__MAN02 &&
                infoList.DEVICE2M__p__MAN02.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE2M__p__MAN02
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE2M__p__MAN02',
                    'MAN02_RM_FYF',
                    '内冷水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo3" :style="{
              background:
                infoList.DEVICE2M__p__MAN01 &&
                infoList.DEVICE2M__p__MAN01.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE2M__p__MAN01
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE2M__p__MAN01',
                    'MAN01_RM_FYF',
                    '夹套水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <!-- <p class="liandong liandong1">F 联动</p>
      <p class="liandong liandong2">联锁</p> -->
    </div>
    <div class="dingwei3">
      <p class="wenzi wenzi1">CW</p>
      <p class="wenzi wenzi2">CW</p>
      <p class="wenzi wenzi3">CW</p>
      <p class="wenzi wenzi4">CW</p>
      <p class="wenzi wenzi5">CW</p>
      <p class="wenzi wenzi6">8# 釜趋势</p>
      <p class="wenzi wenzi7">聚合釜H</p>
      <div class="funei">
        <img
          :src="
            infoList.DEVICE3S.II01 || infoList.DEVICE3S.II02 || 0 | tyFifter
          "
          alt=""
        />
      </div>
      <!-- <div class="famen">
        <img :src="11 | tyFifter" alt="" />
      </div> -->
      <p
        class="shuzhi shuzhi1"
        @click="
          toCompon(2, 'AV03', 'DEVICE3S', 'AV03_FYF', '稳定性得分回写DCS')
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.AV03, 'AV03', 'DEVICE3S', 'AV03_FYF')
        "
      >
        {{ infoList.DEVICE3S.AV03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi1"
        @click="
          toCompon(2, 'AV03', 'DEVICE3S', 'AV03_FYF', '稳定性得分回写DCS')
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.AV03, 'AV03', 'DEVICE3S', 'AV03_FYF')
        "
      >
        {{ infoList.DEVICE3S.AV03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi2"
        @click="toCompon(2, 'VP09', 'DEVICE3S', 'VP09_FYF', '备用阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE3S.VP09, 'VP09', 'DEVICE3S', 'VP09_FYF')
        "
      >
        {{ infoList.DEVICE3S.VP09 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi3"
        @click="toCompon(2, 'TE01', 'DEVICE3S', 'TE01_FYF', '夹套水出口温度')"
        @dblclick="
          Cclick(infoList.DEVICE3S.TE01, 'TE01', 'DEVICE3S', 'TE01_FYF')
        "
      >
        {{ infoList.DEVICE3S.TE01 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi4"
        @click="
          toCompon(2, 'FT01', 'DEVICE3S', 'FT01_FYF', '夹套冷却水出口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.FT01, 'FT01', 'DEVICE3S', 'FT01_FYF')
        "
      >
        {{ infoList.DEVICE3S.FT01 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi5"
        @click="
          toCompon(2, 'VP02', 'DEVICE3S', 'VP02_FYF', '旁路冷却水阀DCS输出')
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.VP02, 'VP02', 'DEVICE3S', 'VP02_FYF')
        "
      >
        {{ infoList.DEVICE3S.VP02 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi6"
        @click="toCompon(2, 'VP01', 'DEVICE3S', 'VP01_FYF', '冷却水阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE3S.VP01, 'VP01', 'DEVICE3S', 'VP01_FYF')
        "
      >
        {{ infoList.DEVICE3S.VP01 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi7"
        @click="toCompon(2, 'TE02', 'DEVICE3S', 'TE02_FYF', '冷却水进口温度')"
        @dblclick="
          Cclick(infoList.DEVICE3S.TE02, 'TE02', 'DEVICE3S', 'TE02_FYF')
        "
      >
        {{ infoList.DEVICE3S.TE02 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi8"
        @click="
          toCompon(
            2,
            'VP03',
            'DEVICE3S',
            'VP03_FYF',
            'F釜夹套冷却水进口调节阀程控设定值'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.VP03, 'VP03', 'DEVICE3S', 'VP03_FYF')
        "
      >
        {{ infoList.DEVICE3S.VP03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi9"
        @click="toCompon(2, 'II01', 'DEVICE3S', 'GL01_FYF', '高搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE3S.II01, 'II01', 'DEVICE3S', 'GL01_FYF')
        "
      >
        {{ infoList.DEVICE3S.II01 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi10"
        @click="toCompon(2, 'II02', 'DEVICE3S', 'GL02_FYF', '低搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE3S.II02, 'II02', 'DEVICE3S', 'GL02_FYF')
        "
      >
        {{ infoList.DEVICE3S.II02 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi11"
        @click="
          toCompon(2, 'TE07', 'DEVICE3S', 'TE07_FYF', '釜顶冷冷却水出口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.TE07, 'TE07', 'DEVICE3S', 'TE07_FYF')
        "
      >
        {{ infoList.DEVICE3S.TE07 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi12"
        @click="
          toCompon(2, 'TE06', 'DEVICE3S', 'TE06_FYF', '釜顶冷冷却水进口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.TE06, 'TE06', 'DEVICE3S', 'TE06_FYF')
        "
      >
        {{ infoList.DEVICE3S.TE06 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi13"
        @click="
          toCompon(
            2,
            'VP08',
            'DEVICE3S',
            'VP08_FYF',
            '釜顶冷冷却水出口阀DCS输出'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.VP08, 'VP08', 'DEVICE3S', 'VP08_FYF')
        "
      >
        {{ infoList.DEVICE3S.VP08 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi14"
        @click="
          toCompon(2, 'FT07', 'DEVICE3S', 'FT07_FYF', '釜顶冷冷却水进口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.FT07, 'FT07', 'DEVICE3S', 'FT07_FYF')
        "
      >
        {{ infoList.DEVICE3S.FT07 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi15"
        @click="
          toCompon(2, 'FT08', 'DEVICE3S', 'FT08_FYF', '釜顶冷冷却水换热值')
        "
        @dblclick="
          Cclick(infoList.DEVICE3S.FT08, 'FT08', 'DEVICE3S', 'FT08_FYF')
        "
      >
        {{ infoList.DEVICE3S.FT08 || "0.00" }} Tcal/h
      </p>
      <p
        class="shuzhi lan shuzhi16"
        @click="toCompon(2, 'TE03', 'DEVICE3S', 'TE03_FYF', '反应釜温度2')"
        @dblclick="
          Cclick(infoList.DEVICE3S.TE03, 'TE03', 'DEVICE3S', 'TE03_FYF')
        "
      >
        {{ infoList.DEVICE3S.TE03 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi17"
        @click="toCompon(2, 'PT01', 'DEVICE3S', 'PT01_FYF', '反应釜压力')"
        @dblclick="
          Cclick(infoList.DEVICE3S.PT01, 'PT01', 'DEVICE3S', 'PT01_FYF')
        "
      >
        {{ infoList.DEVICE3S.PT01 || "0.00" }} MPa
      </p>
      <p
        class="shuzhi lan shuzhi18"
        @click="toCompon(2, 'TE04', 'DEVICE3S', 'TE04_FYF', '反应釜温度1')"
        @dblclick="
          Cclick(infoList.DEVICE3S.TE04, 'TE04', 'DEVICE3S', 'TE04_FYF')
        "
      >
        {{ infoList.DEVICE3S.TE04 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi19"
        @click="toCompon(2, 'AI02', 'DEVICE3S', 'AI02_FYF', '聚合釜反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE3S.AI02, 'AI02', 'DEVICE3S', 'AI02_FYF')
        "
      >
        {{ infoList.DEVICE3S.AI02 || "0.00" }} 分
      </p>
      <p
        class="shuzhi lan shuzhi20"
        @click="toCompon(2, 'AN10', 'DEVICE3V', 'AN10_FYF', '反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE3V.AN10, 'AN10', 'DEVICE3V', 'AN10_FYF')
        "
      >
        {{ infoList.DEVICE3V.AN10 || "0.00" }} 分
      </p>
      <p
        class="shuzhi shuzhi21"
        @click="toCompon(2, 'AN25', 'DEVICE3V', 'AN25_FYF', '釜温60S变化速率')"
        @dblclick="
          Cclick(infoList.DEVICE3V.AN25, 'AN25', 'DEVICE3V', 'AN25_FYF')
        "
      >
        {{ infoList.DEVICE3V.AN25 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi22"
        @click="
          toCompon(2, 'AN21', 'DEVICE3V', 'AN21_FYF', '30S釜压变化速率*1000')
        "
        @dblclick="
          Cclick(infoList.DEVICE3V.AN21, 'AN21', 'DEVICE3V', 'AN21_FYF')
        "
      >
        {{ infoList.DEVICE3V.AN21 || "0.00" }}
      </p>
      <p
        class="shuzhi lan shuzhi23"
        @click="
          toCompon(
            2,
            'AN22',
            'DEVICE3V',
            'AN22_FYF',
            '釜压高限设定（全开循环水）'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE3V.AN22, 'AN22', 'DEVICE3V', 'AN22_FYF')
        "
      >
        {{ infoList.DEVICE3V.AN22 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi24"
        @click="toCompon(2, 'DG03', 'DEVICE3V', 'DG03_FYF', '釜压高标志')"
        @dblclick="
          Cclick(infoList.DEVICE3V.DG03, 'DG03', 'DEVICE3V', 'DG03_FYF')
        "
      >
        {{ infoList.DEVICE3V.DG03 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi25"
        @click="
          toCompon(2, 'AN20', 'DEVICE3V', 'AN20_FYF', '引发剂A/B配方量总和')
        "
        @dblclick="
          Cclick(infoList.DEVICE3V.AN20, 'AN20', 'DEVICE3V', 'AN20_FYF')
        "
      >
        {{ infoList.DEVICE3V.AN20 || "0.00" }} kg
      </p>
      <p class="caozuo caozuo1"  :style="{
              background:
                infoList.DEVICE3M__p__MAN07 &&
                infoList.DEVICE3M__p__MAN07.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE3M__p__MAN07
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE3M__p__MAN07',
                    'MAN07_RM_FYF',
                    '备用手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo2" :style="{
              background:
                infoList.DEVICE3M__p__MAN02 &&
                infoList.DEVICE3M__p__MAN02.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE3M__p__MAN02
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE3M__p__MAN02',
                    'MAN02_RM_FYF',
                    '内冷水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo3" :style="{
              background:
                infoList.DEVICE3M__p__MAN01 &&
                infoList.DEVICE3M__p__MAN01.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE3M__p__MAN01
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE3M__p__MAN01',
                    'MAN01_RM_FYF',
                    '夹套水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <!-- <p class="liandong liandong1">F 联动</p>
      <p class="liandong liandong2">联锁</p> -->
    </div>
    <div class="dingwei4">
      <p class="wenzi wenzi1">CW</p>
      <p class="wenzi wenzi2">CW</p>
      <p class="wenzi wenzi3">CW</p>
      <p class="wenzi wenzi4">CW</p>
      <p class="wenzi wenzi5">CW</p>
      <p class="wenzi wenzi6">9# 釜趋势</p>
      <p class="wenzi wenzi7">聚合釜I</p>
      <div class="funei">
        <img
          :src="
            infoList.DEVICE4S.II01 || infoList.DEVICE4S.II02 || 0 | tyFifter
          "
          alt=""
        />
      </div>
      <!-- <div class="famen">
        <img :src="11 | tyFifter" alt="" />
      </div> -->
      <p
        class="shuzhi shuzhi1"
        @click="
          toCompon(2, 'AV03', 'DEVICE4S', 'AV03_FYF', '稳定性得分回写DCS')
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.AV03, 'AV03', 'DEVICE4S', 'AV03_FYF')
        "
      >
        {{ infoList.DEVICE4S.AV03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi1"
        @click="
          toCompon(2, 'AV03', 'DEVICE4S', 'AV03_FYF', '稳定性得分回写DCS')
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.AV03, 'AV03', 'DEVICE4S', 'AV03_FYF')
        "
      >
        {{ infoList.DEVICE4S.AV03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi2"
        @click="toCompon(2, 'VP09', 'DEVICE4S', 'VP09_FYF', '备用阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE4S.VP09, 'VP09', 'DEVICE4S', 'VP09_FYF')
        "
      >
        {{ infoList.DEVICE4S.VP09 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi3"
        @click="toCompon(2, 'TE01', 'DEVICE4S', 'TE01_FYF', '夹套水出口温度')"
        @dblclick="
          Cclick(infoList.DEVICE4S.TE01, 'TE01', 'DEVICE4S', 'TE01_FYF')
        "
      >
        {{ infoList.DEVICE4S.TE01 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi4"
        @click="
          toCompon(2, 'FT01', 'DEVICE4S', 'FT01_FYF', '夹套冷却水出口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.FT01, 'FT01', 'DEVICE4S', 'FT01_FYF')
        "
      >
        {{ infoList.DEVICE4S.FT01 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi5"
        @click="
          toCompon(2, 'VP02', 'DEVICE4S', 'VP02_FYF', '旁路冷却水阀DCS输出')
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.VP02, 'VP02', 'DEVICE4S', 'VP02_FYF')
        "
      >
        {{ infoList.DEVICE4S.VP02 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi6"
        @click="toCompon(2, 'VP01', 'DEVICE4S', 'VP01_FYF', '冷却水阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE4S.VP01, 'VP01', 'DEVICE4S', 'VP01_FYF')
        "
      >
        {{ infoList.DEVICE4S.VP01 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi7"
        @click="toCompon(2, 'TE02', 'DEVICE4S', 'TE02_FYF', '冷却水进口温度')"
        @dblclick="
          Cclick(infoList.DEVICE4S.TE02, 'TE02', 'DEVICE4S', 'TE02_FYF')
        "
      >
        {{ infoList.DEVICE4S.TE02 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi8"
        @click="
          toCompon(
            2,
            'VP03',
            'DEVICE4S',
            'VP03_FYF',
            'F釜夹套冷却水进口调节阀程控设定值'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.VP03, 'VP03', 'DEVICE4S', 'VP03_FYF')
        "
      >
        {{ infoList.DEVICE4S.VP03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi9"
        @click="toCompon(2, 'II01', 'DEVICE4S', 'GL01_FYF', '高搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE4S.II01, 'II01', 'DEVICE4S', 'GL01_FYF')
        "
      >
        {{ infoList.DEVICE4S.II01 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi10"
        @click="toCompon(2, 'II02', 'DEVICE4S', 'GL02_FYF', '低搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE4S.II02, 'II02', 'DEVICE4S', 'GL02_FYF')
        "
      >
        {{ infoList.DEVICE4S.II02 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi11"
        @click="
          toCompon(2, 'TE07', 'DEVICE4S', 'TE07_FYF', '釜顶冷冷却水出口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.TE07, 'TE07', 'DEVICE4S', 'TE07_FYF')
        "
      >
        {{ infoList.DEVICE4S.TE07 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi12"
        @click="
          toCompon(2, 'TE06', 'DEVICE4S', 'TE06_FYF', '釜顶冷冷却水进口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.TE06, 'TE06', 'DEVICE4S', 'TE06_FYF')
        "
      >
        {{ infoList.DEVICE4S.TE06 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi13"
        @click="
          toCompon(
            2,
            'VP08',
            'DEVICE4S',
            'VP08_FYF',
            '釜顶冷冷却水出口阀DCS输出'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.VP08, 'VP08', 'DEVICE4S', 'VP08_FYF')
        "
      >
        {{ infoList.DEVICE4S.VP08 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi14"
        @click="
          toCompon(2, 'FT07', 'DEVICE4S', 'FT07_FYF', '釜顶冷冷却水进口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.FT07, 'FT07', 'DEVICE4S', 'FT07_FYF')
        "
      >
        {{ infoList.DEVICE4S.FT07 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi15"
        @click="
          toCompon(2, 'FT08', 'DEVICE4S', 'FT08_FYF', '釜顶冷冷却水换热值')
        "
        @dblclick="
          Cclick(infoList.DEVICE4S.FT08, 'FT08', 'DEVICE4S', 'FT08_FYF')
        "
      >
        {{ infoList.DEVICE4S.FT08 || "0.00" }} Tcal/h
      </p>
      <p
        class="shuzhi lan shuzhi16"
        @click="toCompon(2, 'TE03', 'DEVICE4S', 'TE03_FYF', '反应釜温度2')"
        @dblclick="
          Cclick(infoList.DEVICE4S.TE03, 'TE03', 'DEVICE4S', 'TE03_FYF')
        "
      >
        {{ infoList.DEVICE4S.TE03 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi17"
        @click="toCompon(2, 'PT01', 'DEVICE4S', 'PT01_FYF', '反应釜压力')"
        @dblclick="
          Cclick(infoList.DEVICE4S.PT01, 'PT01', 'DEVICE4S', 'PT01_FYF')
        "
      >
        {{ infoList.DEVICE4S.PT01 || "0.00" }} MPa
      </p>
      <p
        class="shuzhi lan shuzhi18"
        @click="toCompon(2, 'TE04', 'DEVICE4S', 'TE04_FYF', '反应釜温度1')"
        @dblclick="
          Cclick(infoList.DEVICE4S.TE04, 'TE04', 'DEVICE4S', 'TE04_FYF')
        "
      >
        {{ infoList.DEVICE4S.TE04 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi19"
        @click="toCompon(2, 'AI02', 'DEVICE4S', 'AI02_FYF', '聚合釜反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE4S.AI02, 'AI02', 'DEVICE4S', 'AI02_FYF')
        "
      >
        {{ infoList.DEVICE4S.AI02 || "0.00" }} 分
      </p>
      <p
        class="shuzhi lan shuzhi20"
        @click="toCompon(2, 'AN10', 'DEVICE4V', 'AN10_FYF', '反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE4V.AN10, 'AN10', 'DEVICE4V', 'AN10_FYF')
        "
      >
        {{ infoList.DEVICE4V.AN10 || "0.00" }} 分
      </p>
      <p
        class="shuzhi shuzhi21"
        @click="toCompon(2, 'AN25', 'DEVICE4V', 'AN25_FYF', '釜温60S变化速率')"
        @dblclick="
          Cclick(infoList.DEVICE4V.AN25, 'AN25', 'DEVICE4V', 'AN25_FYF')
        "
      >
        {{ infoList.DEVICE4V.AN25 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi22"
        @click="
          toCompon(2, 'AN21', 'DEVICE4V', 'AN21_FYF', '30S釜压变化速率*1000')
        "
        @dblclick="
          Cclick(infoList.DEVICE4V.AN21, 'AN21', 'DEVICE4V', 'AN21_FYF')
        "
      >
        {{ infoList.DEVICE4V.AN21 || "0.00" }}
      </p>
      <p
        class="shuzhi lan shuzhi23"
        @click="
          toCompon(
            2,
            'AN22',
            'DEVICE4V',
            'AN22_FYF',
            '釜压高限设定（全开循环水）'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE4V.AN22, 'AN22', 'DEVICE4V', 'AN22_FYF')
        "
      >
        {{ infoList.DEVICE4V.AN22 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi24"
        @click="toCompon(2, 'DG03', 'DEVICE4V', 'DG03_FYF', '釜压高标志')"
        @dblclick="
          Cclick(infoList.DEVICE4V.DG03, 'DG03', 'DEVICE4V', 'DG03_FYF')
        "
      >
        {{ infoList.DEVICE4V.DG03 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi25"
        @click="
          toCompon(2, 'AN20', 'DEVICE4V', 'AN20_FYF', '引发剂A/B配方量总和')
        "
        @dblclick="
          Cclick(infoList.DEVICE4V.AN20, 'AN20', 'DEVICE4V', 'AN20_FYF')
        "
      >
        {{ infoList.DEVICE4V.AN20 || "0.00" }} kg
      </p>
      <p class="caozuo caozuo1"  :style="{
              background:
                infoList.DEVICE4M__p__MAN07 &&
                infoList.DEVICE4M__p__MAN07.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE4M__p__MAN07
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE4M__p__MAN07',
                    'MAN07_RM_FYF',
                    '备用手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo2" :style="{
              background:
                infoList.DEVICE4M__p__MAN02 &&
                infoList.DEVICE4M__p__MAN02.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE4M__p__MAN02
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE4M__p__MAN02',
                    'MAN02_RM_FYF',
                    '内冷水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo3" :style="{
              background:
                infoList.DEVICE4M__p__MAN01 &&
                infoList.DEVICE4M__p__MAN01.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE4M__p__MAN01
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE4M__p__MAN01',
                    'MAN01_RM_FYF',
                    '夹套水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <!-- <p class="liandong liandong1">F 联动</p>
      <p class="liandong liandong2">联锁</p> -->
    </div>
    <div class="dingwei5">
      <p class="wenzi wenzi1">CW</p>
      <p class="wenzi wenzi2">CW</p>
      <p class="wenzi wenzi3">CW</p>
      <p class="wenzi wenzi4">CW</p>
      <p class="wenzi wenzi5">CW</p>
      <p class="wenzi wenzi6">10# 釜趋势</p>
      <p class="wenzi wenzi7">聚合釜J</p>
      <div class="funei">
        <img
          :src="
            infoList.DEVICE5S.II01 || infoList.DEVICE5S.II02 || 0 | tyFifter
          "
          alt=""
        />
      </div>
      <!-- <div class="famen">
        <img :src="11 | tyFifter" alt="" />
      </div> -->
      <p
        class="shuzhi shuzhi1"
        @click="
          toCompon(2, 'AV03', 'DEVICE5S', 'AV03_FYF', '稳定性得分回写DCS')
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.AV03, 'AV03', 'DEVICE5S', 'AV03_FYF')
        "
      >
        {{ infoList.DEVICE5S.AV03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi1"
        @click="
          toCompon(2, 'AV03', 'DEVICE5S', 'AV03_FYF', '稳定性得分回写DCS')
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.AV03, 'AV03', 'DEVICE5S', 'AV03_FYF')
        "
      >
        {{ infoList.DEVICE5S.AV03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi2"
        @click="toCompon(2, 'VP09', 'DEVICE5S', 'VP09_FYF', '备用阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE5S.VP09, 'VP09', 'DEVICE5S', 'VP09_FYF')
        "
      >
        {{ infoList.DEVICE5S.VP09 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi3"
        @click="toCompon(2, 'TE01', 'DEVICE5S', 'TE01_FYF', '夹套水出口温度')"
        @dblclick="
          Cclick(infoList.DEVICE5S.TE01, 'TE01', 'DEVICE5S', 'TE01_FYF')
        "
      >
        {{ infoList.DEVICE5S.TE01 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi4"
        @click="
          toCompon(2, 'FT01', 'DEVICE5S', 'FT01_FYF', '夹套冷却水出口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.FT01, 'FT01', 'DEVICE5S', 'FT01_FYF')
        "
      >
        {{ infoList.DEVICE5S.FT01 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi5"
        @click="
          toCompon(2, 'VP02', 'DEVICE5S', 'VP02_FYF', '旁路冷却水阀DCS输出')
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.VP02, 'VP02', 'DEVICE5S', 'VP02_FYF')
        "
      >
        {{ infoList.DEVICE5S.VP02 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi6"
        @click="toCompon(2, 'VP01', 'DEVICE5S', 'VP01_FYF', '冷却水阀DCS输出')"
        @dblclick="
          Cclick(infoList.DEVICE5S.VP01, 'VP01', 'DEVICE5S', 'VP01_FYF')
        "
      >
        {{ infoList.DEVICE5S.VP01 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi7"
        @click="toCompon(2, 'TE02', 'DEVICE5S', 'TE02_FYF', '冷却水进口温度')"
        @dblclick="
          Cclick(infoList.DEVICE5S.TE02, 'TE02', 'DEVICE5S', 'TE02_FYF')
        "
      >
        {{ infoList.DEVICE5S.TE02 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi8"
        @click="
          toCompon(
            2,
            'VP03',
            'DEVICE5S',
            'VP03_FYF',
            'F釜夹套冷却水进口调节阀程控设定值'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.VP03, 'VP03', 'DEVICE5S', 'VP03_FYF')
        "
      >
        {{ infoList.DEVICE5S.VP03 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi9"
        @click="toCompon(2, 'II01', 'DEVICE5S', 'GL01_FYF', '高搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE5S.II01, 'II01', 'DEVICE5S', 'GL01_FYF')
        "
      >
        {{ infoList.DEVICE5S.II01 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi10"
        @click="toCompon(2, 'II02', 'DEVICE5S', 'GL02_FYF', '低搅功率')"
        @dblclick="
          Cclick(infoList.DEVICE5S.II02, 'II02', 'DEVICE5S', 'GL02_FYF')
        "
      >
        {{ infoList.DEVICE5S.II02 || "0.00" }} KW
      </p>
      <p
        class="shuzhi shuzhi11"
        @click="
          toCompon(2, 'TE07', 'DEVICE5S', 'TE07_FYF', '釜顶冷冷却水出口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.TE07, 'TE07', 'DEVICE5S', 'TE07_FYF')
        "
      >
        {{ infoList.DEVICE5S.TE07 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi12"
        @click="
          toCompon(2, 'TE06', 'DEVICE5S', 'TE06_FYF', '釜顶冷冷却水进口温度')
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.TE06, 'TE06', 'DEVICE5S', 'TE06_FYF')
        "
      >
        {{ infoList.DEVICE5S.TE06 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi shuzhi13"
        @click="
          toCompon(
            2,
            'VP08',
            'DEVICE5S',
            'VP08_FYF',
            '釜顶冷冷却水出口阀DCS输出'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.VP08, 'VP08', 'DEVICE5S', 'VP08_FYF')
        "
      >
        {{ infoList.DEVICE5S.VP08 || "0.00" }} %
      </p>
      <p
        class="shuzhi shuzhi14"
        @click="
          toCompon(2, 'FT07', 'DEVICE5S', 'FT07_FYF', '釜顶冷冷却水进口流量')
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.FT07, 'FT07', 'DEVICE5S', 'FT07_FYF')
        "
      >
        {{ infoList.DEVICE5S.FT07 || "0.00" }} m³/h
      </p>
      <p
        class="shuzhi shuzhi15"
        @click="
          toCompon(2, 'FT08', 'DEVICE5S', 'FT08_FYF', '釜顶冷冷却水换热值')
        "
        @dblclick="
          Cclick(infoList.DEVICE5S.FT08, 'FT08', 'DEVICE5S', 'FT08_FYF')
        "
      >
        {{ infoList.DEVICE5S.FT08 || "0.00" }} Tcal/h
      </p>
      <p
        class="shuzhi lan shuzhi16"
        @click="toCompon(2, 'TE03', 'DEVICE5S', 'TE03_FYF', '反应釜温度2')"
        @dblclick="
          Cclick(infoList.DEVICE5S.TE03, 'TE03', 'DEVICE5S', 'TE03_FYF')
        "
      >
        {{ infoList.DEVICE5S.TE03 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi17"
        @click="toCompon(2, 'PT01', 'DEVICE5S', 'PT01_FYF', '反应釜压力')"
        @dblclick="
          Cclick(infoList.DEVICE5S.PT01, 'PT01', 'DEVICE5S', 'PT01_FYF')
        "
      >
        {{ infoList.DEVICE5S.PT01 || "0.00" }} MPa
      </p>
      <p
        class="shuzhi lan shuzhi18"
        @click="toCompon(2, 'TE04', 'DEVICE5S', 'TE04_FYF', '反应釜温度1')"
        @dblclick="
          Cclick(infoList.DEVICE5S.TE04, 'TE04', 'DEVICE5S', 'TE04_FYF')
        "
      >
        {{ infoList.DEVICE5S.TE04 || "0.00" }} ℃
      </p>
      <p
        class="shuzhi lan shuzhi19"
        @click="toCompon(2, 'AI02', 'DEVICE5S', 'AI02_FYF', '聚合釜反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE5S.AI02, 'AI02', 'DEVICE5S', 'AI02_FYF')
        "
      >
        {{ infoList.DEVICE5S.AI02 || "0.00" }} 分
      </p>
      <p
        class="shuzhi lan shuzhi20"
        @click="toCompon(2, 'AN10', 'DEVICE5V', 'AN10_FYF', '反应时间')"
        @dblclick="
          Cclick(infoList.DEVICE5V.AN10, 'AN10', 'DEVICE5V', 'AN10_FYF')
        "
      >
        {{ infoList.DEVICE5V.AN10 || "0.00" }} 分
      </p>
      <p
        class="shuzhi shuzhi21"
        @click="toCompon(2, 'AN25', 'DEVICE5V', 'AN25_FYF', '釜温60S变化速率')"
        @dblclick="
          Cclick(infoList.DEVICE5V.AN25, 'AN25', 'DEVICE5V', 'AN25_FYF')
        "
      >
        {{ infoList.DEVICE5V.AN25 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi22"
        @click="
          toCompon(2, 'AN21', 'DEVICE5V', 'AN21_FYF', '30S釜压变化速率*1000')
        "
        @dblclick="
          Cclick(infoList.DEVICE5V.AN21, 'AN21', 'DEVICE5V', 'AN21_FYF')
        "
      >
        {{ infoList.DEVICE5V.AN21 || "0.00" }}
      </p>
      <p
        class="shuzhi lan shuzhi23"
        @click="
          toCompon(
            2,
            'AN22',
            'DEVICE5V',
            'AN22_FYF',
            '釜压高限设定（全开循环水）'
          )
        "
        @dblclick="
          Cclick(infoList.DEVICE5V.AN22, 'AN22', 'DEVICE5V', 'AN22_FYF')
        "
      >
        {{ infoList.DEVICE5V.AN22 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi24"
        @click="toCompon(2, 'DG03', 'DEVICE5V', 'DG03_FYF', '釜压高标志')"
        @dblclick="
          Cclick(infoList.DEVICE5V.DG03, 'DG03', 'DEVICE5V', 'DG03_FYF')
        "
      >
        {{ infoList.DEVICE5V.DG03 || "0.00" }}
      </p>
      <p
        class="shuzhi shuzhi25"
        @click="
          toCompon(2, 'AN20', 'DEVICE5V', 'AN20_FYF', '引发剂A/B配方量总和')
        "
        @dblclick="
          Cclick(infoList.DEVICE5V.AN20, 'AN20', 'DEVICE5V', 'AN20_FYF')
        "
      >
        {{ infoList.DEVICE5V.AN20 || "0.00" }} kg
      </p>
      <p class="caozuo caozuo1"  :style="{
              background:
                infoList.DEVICE5M__p__MAN07 &&
                infoList.DEVICE5M__p__MAN07.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE5M__p__MAN07
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE5M__p__MAN07',
                    'MAN07_RM_FYF',
                    '备用手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo2" :style="{
              background:
                infoList.DEVICE5M__p__MAN02 &&
                infoList.DEVICE5M__p__MAN02.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE5M__p__MAN02
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE5M__p__MAN02',
                    'MAN02_RM_FYF',
                    '内冷水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <p class="caozuo caozuo3" :style="{
              background:
                infoList.DEVICE5M__p__MAN01 &&
                infoList.DEVICE5M__p__MAN01.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE5M__p__MAN01
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE5M__p__MAN01',
                    'MAN01_RM_FYF',
                    '夹套水阀手操器'
                  )
                : ''
            "
          >
            A</p>
      <!-- <p class="liandong liandong1">F 联动</p>
      <p class="liandong liandong2">联锁</p> -->
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
  <script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "jhfZy",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  filters: {
    //红绿灯
    tyFifter: (val) => {
      if (val <= 10) {
        return require("@/assets/images/关闭.png");
      } else if (val > 10) {
        return require("@/assets/images/开始.png");
      }
    },
    typeFifter: (val) => {
      if (val <= 1) {
        return require("@/assets/images/关闭.png");
      } else if (val > 1) {
        return require("@/assets/images/开始.png");
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.dabox {
  box-sizing: border-box;
  width: 100%;
  height: 82vh;
  margin-left: 2vw;
  margin-top: 1vh;
  display: flex;
  flex-wrap: wrap;
  .se {
    background-color: #2b5567;
  }
  .shu {
    position: absolute;
    width: 2px;
    height: 39%;
  }
  .heng {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 49vh;
  }
  .shu1 {
    left: 50vw;
  }
  .shu2 {
    top: 49vh;
    left: 34.5vw;
  }
  .shu3 {
    top: 49vh;
    right: 35.4vw;
  }
  .dingwei1,
  .dingwei2 {
    position: relative;
    font-size: 0.8vw;
    width: 25%;
    height: 45%;
    color: #fff;
    // border: 1px solid #fff;
    background-image: url("~@/assets/images/釜趋势Zy.png");
    background-size: 100% 100%;
    margin-left: 15vw;
  }
  .dingwei3,
  .dingwei4,
  .dingwei5 {
    position: relative;
    font-size: 0.8vw;
    width: 25%;
    height: 45%;
    color: #fff;
    // border: 1px solid #fff;
    background-image: url("~@/assets/images/釜趋势Zy.png");
    background-size: 100% 100%;
    margin-left: 5vw;
  }
  .funei {
    width: 1vw;
    position: absolute;
    top: 15.3vh;
    left: 13.6vw;
  }
  .famen {
    position: absolute;
    top: 18.84vh;
    left: 2.9vw;
    img {
      width: 1.6vw;
    }
  }
  .caozuo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2afc30;
    color: #fff;
    width: 1vw;
    height: 2vh;
    box-sizing: border-box;
    border: 1px solid #fdf061;
    font-size: 0.8vw;
    cursor: pointer;
    z-index: 11; /* 提高层级 */
  }
  .liandong {
    position: absolute;
    color: #000;
    background-color: #fff;
    cursor: pointer; /* 指针形状 */
    width: 3vw;
    height: 2vh;
    font-size: 0.7vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wenzi {
    position: absolute;
  }
  .lan {
    color: #357eeb;
  }
  .shuzhi {
    position: absolute;
    font-size: 0.7vw;cursor: pointer;
  }
  .wenzi1 {
    top: 13.3vh;
    left: -2vw;
  }
  .wenzi2 {
    top: 26.7vh;
    left: -2vw;
  }
  .wenzi3 {
    top: 32.8vh;
    left: -2vw;
  }
  .wenzi4 {
    top: 4.8vh;
    right: -2vw;
  }
  .wenzi5 {
    top: 8.5vh;
    right: -2vw;
  }
  .wenzi6 {
    top: 24.3vh;
    right: 9.1vw;
  }
  .wenzi7 {
    color: #000;
    font-size: 1vw;
    font-weight: 700;
    top: 21vh;
    right: 9.1vw;
  }
  .shuzhi1 {
    top: 3.2vh;
    left: 6.2vw;
  }
  .shuzhi2 {
    top: 7.4vh;
    left: 3.3vw;
  }
  .shuzhi3 {
    top: 12vh;
    left: 4vw;
  }
  .shuzhi4 {
    top: 14.8vh;
    left: 4vw;
  }
  .shuzhi5 {
    bottom: 5.8vh;
    left: 2vw;
  }
  .shuzhi6 {
    bottom: 0vh;
    left: 2vw;
  }
  .shuzhi7 {
    bottom: 0vh;
    left: 7vw;
  }
  .shuzhi8 {
    bottom: -1.5vh;
    left: 7vw;
  }
  .shuzhi9 {
    bottom: 2.5vh;
    right: 6.5vw;
  }
  .shuzhi10 {
    bottom: 0.5vh;
    right: 6.5vw;
  }
  .shuzhi11 {
    top: 3.5vh;
    right: 6.5vw;
  }
  .shuzhi12 {
    top: 7.5vh;
    right: 6.5vw;
  }
  .shuzhi13 {
    top: 6.7vh;
    right: 1.6vw;
  }
  .shuzhi14 {
    top: 11vh;
    right: 0vw;
  }
  .shuzhi15 {
    top: 13vh;
    right: 0vw;
  }
  .shuzhi16 {
    top: 11.5vh;
    left: 12.8vw;
  }
  .shuzhi17 {
    top: 13.5vh;
    left: 12.8vw;
  }
  .shuzhi18 {
    top: 18.5vh;
    left: 9.5vw;
  }
  .shuzhi19 {
    top: 18.5vh;
    left: 16vw;
  }
  .shuzhi20 {
    top: 21.5vh;
    left: 20vw;
  }
  .shuzhi21 {
    bottom: 10vh;
    right: -2vw;
  }
  .shuzhi22 {
    bottom: 8vh;
    right: -2vw;
  }
  .shuzhi23 {
    bottom: 6vh;
    right: -2vw;
  }
  .shuzhi24 {
    bottom: 4vh;
    right: -2vw;
  }
  .shuzhi25 {
    bottom: 2vh;
    right: -2vw;
  }
  .caozuo1 {
    top: 0vh;
    left: 4vw;
  }
  .caozuo2 {
    top: 0vh;
    right: 2.2vw;
  }
  .caozuo3 {
    bottom: 12vh;
    left: 0vw;
  }
  .liandong1 {
    bottom: 0vh;
    right: 2vw;
  }
  .liandong2 {
    bottom: 13vh;
    right: -2vw;
  }
}
</style>